<template>
  <b-modal id="rejectModal" centered hide-footer header-bg-variant="white">
    <div class="d-flex flex-column justify-content-center align-items-start px-1">
      <h3 class="mb-1">Rejeitar redesconto</h3>
      <span class="mb-2">
        Informe abaixo o motivo da rejeição do redesconto.
      </span>
      <validation-observer ref="resetForm" #default="{ invalid }" class="w-100">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Descrição" label-for="tipo">
              <validation-provider #default="{ errors }" name="Descrição" rules="required">
                <b-form-textarea
                  v-model="descricao"
                  :state="errors.length > 0 ? false : null"
                  name="descricao"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex flex-row justify-content-around mt-2">
          <b-button
            variant="outline-secondary"
            class="mb-1 mr-1 text-dark font-weight-bolder"
            @click="$bvModal.hide('cancelModal')"
          >
            Voltar
          </b-button>
          <b-button
            variant="secondary"
            class="mb-1 bg-light-secondary font-weight-bolder"
            :disabled="invalid"
            @click="cancelConfirm"
          >
            Confirmar
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BFormTextarea, BFormGroup, BForm, BRow, BCol } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'

export default {
  name: 'RejectModal',
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BButton,
    flatPickr,
    BFormGroup,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reference_date: new Date(),
      config: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      descricao: '',
    }
  },
  methods: {
    async cancelConfirm() {
      this.$swal({
        title: 'Confirmar rejeição?',
        text: 'O redesconto será cancelado.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Voltar',
      }).then((result) => {
        if (result.value) this.cancel()
      })
    },
    async cancel() {
      try {
        const payload = {
          id: this.selectedItem.id,
          motivo: this.descricao,
          data_referencia: this.reference_date,
        }
        await this.$store.dispatch('admin/setRejeitado', { id: this.selectedItem.id, motivo_rejeicao: this.descricao })
        this.$swal({
          title: 'Redesconto rejeitado!',
          text: 'O redesconto foi rejeitado com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        this.descricao = ''
        this.$parent.$refs.redescontosDatatable.getData()
        this.$bvModal.hide('rejectModal')
      } catch (error) {}
    },
  },
}
</script>
